<template>
  <div>
    <CCardBody class="c-card-body">
      <h2>Статистика ответов на вопросы теста</h2>
      <br />
      <br />
      <div class="questions-container" v-if="questions">
        <div
          class="question-container"
          v-for="question in questions"
          :key="question.id"
        >
          <div class="question-text" colspan="3">
            <strong>{{ question.text }}</strong>
          </div>
          <div class="answers-container">
            <div
              class="answer-container"
              v-for="answer in question.answers"
              :key="answer.id"
            >
              <div class="answer-text">{{ answer.text }}</div>
              <div class="answers-count">
                <a @click="downloadAnswerReport(question.id, answer.id)"
                  >Ответов: {{ numberFormat(answer.count) }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <CButton color="primary" @click="downloadTotalAnswerReport">Скачать</CButton>
          <CButton color="primary" @click="downloadZodiacSignsReport">Скачать знаки зодиака</CButton>
          <CButton color="primary" @click="downloadPersonTypesReport">Скачать типы личностей</CButton>
    </CCardBody>
  </div>
</template>

<style scoped>
.card {
  border: none !important;
}

.card-header {
  border: none;
}

.card-header-main {
  background: rgb(50, 31, 219);
  width: 100%;
  min-height: 50px;
  display: grid;
  align-content: center;
  font-size: 18px;
}
.card-header-in {
  background: rgb(47, 143, 232);
  width: 100%;
  min-height: 50px;
  display: grid;
  align-content: center;
  font-size: 18px;
}
</style>

<style>
.question-container {
  margin-bottom: 20px;
}

.questions-container {
  margin-bottom: 20px;
}

.question-text {
  margin-bottom: 20px;
}

.answers-container {
  display: flex;
  justify-content: space-between;
}

.answer-container {
  width: 30%;
}

.answers-count a {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<script>
import { mapActions } from "vuex";
import { reportingService } from "../services";

export default {
  name: "TotalAnswerCountStats",
  data() {
    return {
      questions: null,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "reporting/getTotalAnswerCountJSONSuccess") {
        this.questions = mutation.payload;
      }
    });
    this["reporting/getTotalAnswerCountJSON"]();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    ...mapActions(["reporting/getTotalAnswerCountJSON"]),
    getPercentValue(value, maxValue) {
      return ((value * 100) / maxValue).toFixed(2);
    },
    numberFormat(value) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
    downloadAnswerReport(questionId, answerId) {
      reportingService.getAnswerReportXLSX(questionId, answerId);
    },
    downloadTotalAnswerReport() {
      reportingService.getTotalAnswerCountXLSX();
    },
    downloadZodiacSignsReport() {
      reportingService.getZodiacSignsReportXLSX();
    },
    downloadPersonTypesReport() {
      reportingService.getPersonTypesReportXLSX();
    },
  },
};
</script>
